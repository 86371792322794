<!-- 验收记录 -->
<template>
  <div class="pagePadding">
    <Form inline class="formMarginBtm20 clearfix">
      <!-- 入库单号 -->
      <FormItem>
        <span>入库单号：</span>
        <Input placeholder="请输入" v-model="queryFrom.order_number" clearable class="iviewIptWidth250"></Input>
      </FormItem>
      <!-- 供应商名称 -->
      <FormItem class="marginLeft40">
        <span>供应商名称：</span>
        <Select placeholder="请选择" v-model="queryFrom.supplier_id" clearable filterable @on-change="supplierChange" class="iviewIptWidth250">
          <Option v-for="(item, index) in supplierSelect" :value="item.id" :key="index">{{ item.name }}</Option>
        </Select>
      </FormItem>
      <FormItem class="marginLeft40">
        <span>产品编号：</span>
        <i-input placeholder="请输入" class="iviewIptWidth250" v-model="queryFrom.product_code"></i-input>
      </FormItem>
      <FormItem class="marginLeft40" style="margin-right: 30px !important;">
        <span>产品名称：</span>
        <Select class="iviewIptWidth250 marginRight60" @on-change="productChange" clearable filterable placeholder="请选择" ref="name_select" v-model="queryFrom.product_id">
          <Option v-for="(item, index) in productList" :value="item.product_id" :key="index">{{ item.product_name }}</Option>
        </Select>
      </FormItem>
      <FormItem>
        <span>规格型号/SKU：</span>
        <Select class="iviewIptWidth250 marginRight60" multiple filterable clearable v-model="queryFrom.product_code_array" :max-tag-count="queryFrom.product_code_array.length == 1 ? 1 : 0">
          <Option v-for="(item, index) in modelList" :value="item.specification_id" :key="index" :label="item.model_name">
            <span>{{ item.model_name }}</span>
            <span style="float: right; color: #ccc; margin-right: 20px;">{{ item.item_number }}</span>
          </Option>
        </Select>
      </FormItem>
      <!-- 入库仓库 -->
      <FormItem class="marginLeft40">
        <span>入库仓库：</span>
        <Select placeholder="请选择" v-model="queryFrom.warehouse_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in storeList" :value="item.warehouse_id" :key="index">{{ item.warehouse_name }}</Option>
        </Select>
      </FormItem>
      <!-- 入库时间 -->
      <FormItem class="marginLeft40">
        <span>入库时间：</span>
        <DatePicker type="daterange" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth250" v-model="queryFrom.create_time"></DatePicker>
      </FormItem>
      <!-- 验收员 -->
      <FormItem>
        <span>验收员：</span>
        <Select placeholder="请选择" v-model="queryFrom.acceptor_id" clearable filterable class="iviewIptWidth250">
          <Option v-for="(item, index) in acceptSelect" :value="item.id" :key="index">{{ item.user_name }}</Option>
        </Select>
      </FormItem>
      <!-- 按钮 -->
      <FormItem class="po-create-number marginRight0" :label-width="20">
        <span class="pageBtn finger btnSure" @click="searchItem">查询</span>
        <span class="longTextBtn finger btnAdd marginLeft20" @click="exportMerge">
          <Spins style="display: inline;" v-if="flx">
            <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
          </Spins>
          合并导出</span
        >
      </FormItem>
    </Form>
    <!-- <Table :productList="listColumns" :productData="listData" :pages="pages" @change-page="changePage" totalText="条记录" :total="total">
      <div slot="summary" class="summary">
        总数量：<span class="color389">{{ total_number }}</span> 合计金额：<span class="color389">¥{{ total_amount }}</span>
      </div>
    </Table> -->
    <div class="clearfix tabDiv">
      <Spin fix v-if="tabloading"></Spin>
      <Table :columns="listColumns" :data="listData" highlight-row border :row-class-name="rowClassName"></Table>
      <div class="summary" v-if="listData && listData.length > 0">
        总数量：<span class="color389 marginRight20">{{ total_number }}</span> 合计金额：<span class="color389">{{ $utils.formatMoney(total_amount, true) }}</span>
      </div>
      <div class="pages" v-if="listData !== null && listData.length > 0 && total">
        <Page v-if="pages && total <= 60" :total="total" :current="pages.page" @on-change="changePage" class="fl" />
        <Page v-if="pages && total > 60" :total="total" :current="pages.page" @on-change="changePage" show-elevator class="fl" />
        <div class="fr totalFont" v-if="total">共计{{ total }}条记录</div>
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="chaxunFlag === true">
        <img style="margin-top: 100px;" src="../../assets/images/erpNoData.png" />
        <p class="noDataImg">请选择查询条件进行查询</p>
      </div>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="listData !== null && listData.length == 0 && !chaxunFlag">
        <img style="margin-top: 100px;" src="../../assets/images/tableNoData.png" />
      </div>
    </div>

    <Modal v-model="status" title="请选择模版" cancel-text @on-cancel="status = false" width="568">
      <div class="clearfix paddingBot40px">
        <div class="item">
          <span class="itemTitle">模版类型：</span>
          <Select placeholder="请选择" v-model="template_type" filterable class="iviewIptWidth307" @on-change="typeChange">
            <Option v-for="(item, index) in TemplateList" :value="item.typeID" :key="index">{{ item.typeName }}</Option>
          </Select>
        </div>
        <div class="item">
          <span class="itemTitle">模版名称：</span>
          <Select placeholder="请选择" v-model="template_id" filterable class="iviewIptWidth307" :disabled="!template_type">
            <Option v-for="(item, index) in typeList" :value="item.template_id" :key="index">{{ item.template_name }}</Option>
          </Select>
        </div>
        <!-- <div class="item">
          <span class="itemTitle">每页行数：</span>
          <InputNumber  :min="0" :precision="0" v-model="paging" :active-change="false"  class="iviewIptWidth307"></InputNumber>
        </div> -->
      </div>
      <div slot="footer">
        <span class="pageBtn finger btnSure" @click="surePrint">确定</span>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'acceptanceRecord',
  data() {
    return {
      chaxunFlag: true,
      tabloading: false,
      productList: [],
      modelList: [],
      TemplateList: [
        { typeName: '采购单', typeID: 1 },
        { typeName: '收货验收单', typeID: 2 },
        { typeName: '入库单', typeID: 3 },
        { typeName: '销售单', typeID: 4 },
        { typeName: '拣货单', typeID: 5 },
        { typeName: '出库单', typeID: 6 },
        { typeName: '采购记录', typeID: 7 },
        { typeName: '收货验收记录', typeID: 8 },
        { typeName: '入库记录', typeID: 9 },
        { typeName: '销售记录', typeID: 10 },
        { typeName: '拣货记录', typeID: 11 },
        { typeName: '出库记录', typeID: 12 },
      ],
      template_id: '',
      template_type: 2,
      typeList: [],
      status: false,
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 75,
        },
        {
          title: '入库单号',
          key: 'order_number',
          align: 'center',
          width: 220,
        },
        {
          title: '供应商名称',
          key: 'supplier_name',
          align: 'center',
          minWidth: 260,
        },
        {
          title: '入库仓库',
          key: 'warehouse_name',
          align: 'center',
          width: 136,
        },
        {
          title: '入库时间',
          key: 'create_time',
          align: 'center',
          width: 136,
        },
        {
          title: '验收员',
          key: 'acceptor',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          minWidth: 120,
        },
        {
          title: '金额',
          key: 'total_amount',
          align: 'center',
          minWidth: 120,
          render: (h, param) => {
            let str = this.$utils.formatMoney(param.row.total_amount, true)
            return h(
              'div',
              {},
              // param.row.total_amount
              str
            )
          },
        },
        {
          title: '操作',
          align: 'center',
          width: 189,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: param.row.related_status == 3 ? '#fa3e3b' : '#389afc',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.details(param.row)
                    },
                  },
                },
                '详情'
              ),
              h(
                'span',
                {
                  style: {
                    color: param.row.related_status == 3 ? '#fa3e3b' : '#389afc',
                    marginLeft: '20px',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      this.output(param.row.order_number)
                    },
                  },
                },
                '导出'
              ),
              h(
                'span',
                {
                  style: {
                    color: param.row.related_status == 3 ? '#fa3e3b' : '#389afc',
                    marginLeft: '20px',
                  },
                  class: 'tableFont',
                  on: {
                    click: () => {
                      // this.$Message.warning('正在开发中')
                      this.clickId = param.row.id
                      this.status = true
                      this.getTemplate()
                    },
                  },
                },
                '打印'
              ),
            ])
          },
        },
      ],
      flx: false,
      clickId: null,
      /* 供应商下拉 */
      supplierSelect: [],
      storeList: [], // 仓库下拉
      acceptSelect: [], // 收货人下拉
      total: 0, // 信息条数
      total_amount: 0, // 金额总数
      total_number: 0, // 商品总数量
      // 查询参数
      queryFrom: {
        product_code: '',
        order_number: '',
        warehouse_id: '',
        create_time: [],
        acceptor_id: '',
        supplier_id: '',
        product_id: '',
        product_code_array: [],
      },
      searchObj: {}, // 搜索条件
      pages: {
        page: 1,
        rows: 10,
      },
      paging: null,
    }
  },
  methods: {
    // 供应商改变
    supplierChange(e) {
      this.queryFrom.product_id = ''
      // if (!e) return
      this.queryProductSelect(e)
      this.queryProductCodeSelect(null)
    },
    // 获取供应商下的产品下拉选择
    queryProductSelect(id) {
      this.$http.get(this.$api.receiptProductList, { supplier_id: id }, false).then(res => {
        this.productList = res.data
      })
    },
    // 产品名称改变
    productChange(e) {
      this.queryFrom.product_code_array = ''
      // if (!e) return
      this.modelList = []
      this.queryProductCodeSelect(e)
    },
    // 获取供应商下指定产品下的规格型号
    queryProductCodeSelect(id) {
      this.$http.get(this.$api.acceptanceCodeSelect, { supplier_id: this.queryFrom.supplier_id, product_id: id }, false).then(res => {
        this.modelList = res.data
      })
    },
    // 获取最近的模板
    getTemplate() {
      // 获取模板名称
      this.$http.get(this.$api.templateInfo, { template_type: this.template_type }, false).then(res => {
        this.typeList = res.data
      })
      // 获取模板记录
      this.$http.get(this.$api.recentTemplate, { template_type: this.template_type }).then(res => {
        if (res.data) {
          this.template_id = res.data.template_id
        }
      })
    },
    surePrint() {
      let api_info = `/acceptance_manage/detail?id=${this.clickId}`
      // for (let objName in this.queryFrom) {
      //   console.log(objName)
      //   if (this.queryFrom[objName]) {
      //     api_info = api_info + `${objName}=${this.queryFrom[objName]}&`
      //   }
      // }
      if (!this.template_type) {
        this.$Message.warning('请选择模版类型')
        return
      }
      if (!this.template_id) {
        this.$Message.warning('请选择模版名称')
        return
      }
      this.$http.downFile(this.$api.templatePrint, { template_id: this.template_id, api_info: api_info, paging: this.paging }, true).then(res => {
        this.status = false
        this.$Message.success('导出成功')
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '验收记录.xlsx'
        aLink.click()
      })
    },
    // 发送请求获取 模版名称
    typeChange(e) {
      if (!e) return
      this.$http.get(this.$api.templateInfo, { template_type: e }, false).then(res => {
        this.typeList = res.data
      })
    },
    // 反冲成功的行标红
    rowClassName(row, index) {
      if (row.related_status == 3) return 'redRow'
      return ''
    },
    // 详情
    details(row) {
      let order_id = row.id
      this.$router.push({
        path: '/detailsAcceptanceRecord',
        query: {
          order_id: order_id,
          related_status: row.related_status,
        },
      })
    },
    // 分页
    changePage(e) {
      this.pages.page = e
      this.getTableList(this.pages, this.searchObj)
    },
    // 获取表格数据
    getTableList(pages, searchItem) {
      this.tabloading = true
      let obj = {}
      Object.assign(obj, pages, searchItem)
      this.$http.get(this.$api.acceptanceManage, obj).then(res => {
        this.tabloading = false
        if (res.status) {
          for (const item of res.data) {
            item.create_time = item.create_time ? this.$moment.unix(item.create_time).format('YYYY-MM-DD') : ''
          }
          this.listData = res.data
          this.total = res.total
          this.total_amount = res.total_amount
          this.total_number = res.total_number
        }
      })
    },
    /* 获取下拉列表 */
    getSelect() {
      // 获取供应商下拉
      this.$http.get(this.$api.getSupplierNameList).then(res => {
        if (res.status) {
          this.supplierSelect = res.data
        }
      })
      // 查询仓库列表
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data).then(res => {
        this.storeList = res.data
      })
      // 获取收货人下拉
      let user_type = '3'
      this.$http.get(this.$api.acceptPerson, { user_type }).then(res => {
        if (res.status) {
          this.acceptSelect = res.data
        }
      })
    },
    // 搜索
    searchItem() {
      this.chaxunFlag = false
      let create_time_start = this.queryFrom.create_time[0]
      create_time_start = create_time_start ? this.$moment(create_time_start).format('YYYY-MM-DD') : ''
      let create_time_end = this.queryFrom.create_time[1]
      create_time_end = create_time_end ? this.$moment(create_time_end).format('YYYY-MM-DD') : ''
      let order_number = this.queryFrom.order_number
      let warehouse_id = this.queryFrom.warehouse_id
      let acceptor_id = this.queryFrom.acceptor_id
      let supplier_id = this.queryFrom.supplier_id
      let product_id = this.queryFrom.product_id
      let product_code = this.queryFrom.product_code
      let specification_id_str = this.queryFrom.product_code_array ? this.queryFrom.product_code_array.join(',') : ''
      let obj = {
        order_number,
        warehouse_id,
        create_time_start,
        create_time_end,
        acceptor_id,
        supplier_id,
        product_id,
        specification_id_str,
        product_code,
      }
      this.searchObj = obj
      this.pages = { page: 1, rows: 10 }
      this.getTableList(this.pages, this.searchObj)
    },
    // 合并导出
    exportMerge() {
      let data = {
        product_code: this.queryFrom.product_code,
        order_number: this.queryFrom.order_number,
        warehouse_id: this.queryFrom.warehouse_id,
        supplier_id: this.queryFrom.supplier_id,
        create_time_start: this.queryFrom.create_time[0] ? this.$moment(this.queryFrom.create_time[0]).format('YYYY-MM-DD') : '',
        create_time_end: this.queryFrom.create_time[1] ? this.$moment(this.queryFrom.create_time[1]).format('YYYY-MM-DD') : '',
        acceptor_id: this.queryFrom.acceptor_id,
      }
      this.flx = true
      this.$http.downFile(this.$api.acceptanceBatchExport, data).then(res => {
        this.flx = false
        let blob = new Blob([res.data], { type: 'application/zip' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '验收记录合并导出表.zip'
        aLink.click()
      })
    },
    // 导出
    output(order_number) {
      let data = {
        order_number: order_number,
      }
      this.$http.downFile(this.$api.acceptanceExport, data).then(res => {
        let blob = new Blob([res.data], { type: 'application/x-xlsx' })
        const aLink = document.createElement('a')
        aLink.href = URL.createObjectURL(blob)
        aLink.download = '验收记录导出表.xlsx'
        aLink.click()
      })
    },
  },
  created() {
    // this.getTableList(this.pages, {})
    this.getSelect()
    this.getTemplate()
    this.queryProductSelect(null)
    this.queryProductCodeSelect(null)
  },
}
</script>

<style scoped lang="less">
// 表格
.tabDiv {
  .totalFont {
    font-size: 14px;
  }
  .pages {
    margin-top: 10px;
    overflow: hidden;
    line-height: 32px;
  }
}
.paddingBot40px {
  padding-bottom: 40px;
}
.item {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-bottom: 20px;
  .itemTitle {
    width: 100px;
    color: #000;
    text-align: justify;
    height: 32px;
    line-height: 32px;
  }
  .itemTitle::after {
    content: '';
    display: inline-block;
    padding-left: 100%;
  }
}
.noDataImg {
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translateX(-54%) translateY(-50%);
  font-size: 22px;
  color: #ccc;
}
</style>
